import FF from "./FormField.module.css";

import { forwardRef, type ReactNode } from "react";
import { Radio } from "react-aria-components";
import { cn } from "../../utils/classNames.ts";
import { MaskIcon } from "../icon/MaskIcon.tsx";

/** Properties that are usually declared by "radio items" */
export interface IconRadioContentProps {
    id: string;
    title: ReactNode;
    icon: string;
    desc?: string;
}

export interface IconRadioProps extends IconRadioContentProps {
    //#region Visual properties
    wide?: boolean;
    //#endregion
}

export const IconRadio = forwardRef<HTMLLabelElement, IconRadioProps>(function IconRadio(
    { icon, id, title, desc, wide },
    ref,
) {
    return (
        <Radio ref={ref} value={id} className={cn(FF.FormFieldIconRadio, wide && FF.wide)}>
            <MaskIcon className={`${icon} size-2.5rem`} />
            {desc ? (
                <div className={FF.content}>
                    <div className={FF.title}>{title}</div>
                    <div>{desc}</div>
                </div>
            ) : (
                <div className={FF.title}>{title}</div>
            )}
        </Radio>
    );
});
