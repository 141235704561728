import { createLazyFileRoute } from "@tanstack/react-router";
import { TokenView } from "../../../../../modules/access/token/TokenView.tsx";

export const Route = createLazyFileRoute("/_main/access/token/$id/")({
    component: Component,
});

function Component() {
    const id = Number(Route.useParams().id);
    // NB: `key` prop ensures that the component state is reset when the route changes (to prevent eg. `isOpen` etc. states from remaining the same)
    return <TokenView key={id} accessId={id} />;
}
